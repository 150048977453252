<template>
  <header-block name="Editor" :page="activePage.name">
    <div class="col">
      <nav class="btn-links mx-auto">
        <button :class="{ active: size === 'wide' }" v-on:click="size = 'wide'">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            fill="currentColor"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              d="M4 5v11h16V5H4zm-2-.993C2 3.451 2.455 3 2.992 3h18.016c.548 0 .992.449.992 1.007V18H2V4.007zM1 19h22v2H1v-2z"
            />
          </svg>
        </button>
        <button
          :class="{ active: size === 'tablet' }"
          v-on:click="size = 'tablet'"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            fill="currentColor"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              d="M6 4v16h12V4H6zM5 2h14a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1zm7 15a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"
            />
          </svg>
        </button>
        <button
          :class="{ active: size === 'phone' }"
          v-on:click="size = 'phone'"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            fill="currentColor"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              d="M7 4v16h10V4H7zM6 2h12a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1zm6 15a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"
            />
          </svg>
        </button>
      </nav>
    </div>
    <div class="col-4 text-end">
      <nav class="me-3">
        <button v-on:click="changeGlobalSettingsModal">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              d="M7.05 14.121L4.93 16.243l2.828 2.828L19.071 7.757 16.243 4.93 14.12 7.05l1.415 1.414L14.12 9.88l-1.414-1.415-1.414 1.415 1.414 1.414-1.414 1.414-1.414-1.414-1.415 1.414 1.415 1.414-1.415 1.415L7.05 14.12zm9.9-11.313l4.242 4.242a1 1 0 0 1 0 1.414L8.464 21.192a1 1 0 0 1-1.414 0L2.808 16.95a1 1 0 0 1 0-1.414L15.536 2.808a1 1 0 0 1 1.414 0zM14.12 18.363l1.415-1.414 2.242 2.243h1.414v-1.414l-2.242-2.243 1.414-1.414L21 16.757V21h-4.242l-2.637-2.637zM5.636 9.878L2.807 7.05a1 1 0 0 1 0-1.415l2.829-2.828a1 1 0 0 1 1.414 0L9.88 5.635 8.464 7.05 6.343 4.928 4.929 6.343l2.121 2.12-1.414 1.415z"
            />
          </svg>
        </button>
      </nav>
      <nav>
        <!--
        <button>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            fill="currentColor"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              d="M5.828 7l2.536 2.536L6.95 10.95 2 6l4.95-4.95 1.414 1.414L5.828 5H13a8 8 0 1 1 0 16H4v-2h9a6 6 0 1 0 0-12H5.828z"
            />
          </svg>
        </button>
        <button>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            fill="currentColor"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              d="M18.172 7H11a6 6 0 1 0 0 12h9v2h-9a8 8 0 1 1 0-16h7.172l-2.536-2.536L17.05 1.05 22 6l-4.95 4.95-1.414-1.414L18.172 7z"
            />
          </svg>
        </button>-->
        <button v-on:click="save()">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            fill="currentColor"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              d="M18 19h1V6.828L17.172 5H16v4H7V5H5v14h1v-7h12v7zM4 3h14l2.707 2.707a1 1 0 0 1 .293.707V20a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm4 11v5h8v-5H8z"
            />
          </svg>
        </button>
      </nav>
    </div>
  </header-block>
  <div class="container-fluid mt-3">
    <div class="row">
      <div class="col-2 max-height">
        <div class="white-box">
          <div class="row align-items-center">
            <div class="col">
              <h1>Blocks</h1>
            </div>
            <div class="col-auto">
              <button v-on:click="addBlockShow = true">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  fill="currentColor"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                  />
                </svg>
              </button>
            </div>
          </div>
          <draggable
            class="drag-area blocks"
            tag="ul"
            :list="blocks"
            :group="{ name: 'g1' }"
            item-key="name"
            v-on:end="moveEvent"
            v-bind="dragOptions"
          >
            <template #item="{ element }">
              <li
                class="align-items-center row"
                v-on:click="showEditBlock(element)"
              >
                <span v-html="element.icon" class="col-auto"></span>
                <span class="col">{{ element.name }}</span>
              </li>
            </template>
          </draggable>
        </div>
      </div>
      <div class="col-7 preview-container" ref="outsideContainer">
        <div
          ref="container"
          class="iframe-container"
          :class="[size]"
          :style="`transform: scale(${rate});`"
        >
          <iframe
            ref="preview"
            :style="[height ? 'height: ' + height + 'px' : '']"
          ></iframe>
        </div>
        <div
          class="preview-loading"
          :class="{ show: !loaded.style || !loaded.script }"
        >
          <div
            ref="loadBar"
            data-preset="bubble"
            class="ldBar label-center"
          ></div>
        </div>
      </div>
      <div class="col-3 max-height" v-if="selectedBlock">
        <section class="white-box">
          <div class="row align-items-center">
            <div class="col-auto" v-html="selectedBlock?.icon"></div>
            <div class="col">
              <h1>Module</h1>
              <div class="row">
                <div class="col">
                  <h2>{{ selectedBlock?.name }}</h2>
                </div>
                <div class="col-auto">
                  <button v-on:click="deleteBlock">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path
                        d="M17 6h5v2h-2v13a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V8H2V6h5V3a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v3zm1 2H6v12h12V8zm-9 3h2v6H9v-6zm4 0h2v6h-2v-6zM9 4v2h6V4H9z"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div class="white-box">
          <div class="row mb-3">
            <div class="col-12 mb-2">
              <h1>Style</h1>
            </div>
            <!--
            <div class="col-6">
              <div class="input-group">
                <label>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path
                      d="M19.228 18.732l1.768-1.768 1.767 1.768a2.5 2.5 0 1 1-3.535 0zM8.878 1.08l11.314 11.313a1 1 0 0 1 0 1.415l-8.485 8.485a1 1 0 0 1-1.414 0l-8.485-8.485a1 1 0 0 1 0-1.415l7.778-7.778-2.122-2.121L8.88 1.08zM11 6.03L3.929 13.1H18.07L11 6.03z"
                    />
                  </svg>
                </label>
                <input type="color" />
              </div>
            </div>
            <div class="col-6">
              <div class="input-group">
                <label>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path
                      d="M15.246 14H8.754l-1.6 4H5l6-15h2l6 15h-2.154l-1.6-4zm-.8-2L12 5.885 9.554 12h4.892zM3 20h18v2H3v-2z"
                    />
                  </svg>
                </label>
                <input type="color" />
              </div>
            </div>
            -->
          </div>
          <div
            class="row"
            v-for="(style, is) in selectedBlock?.styles"
            :key="is"
          >
            <div class="col-12 mb-2">
              <div class="input-group" v-if="style.classes">
                <label class="half">{{ style.name }}</label>
                <select
                  v-model="values[is]"
                  v-on:change="
                    changeBlockStyle(selectedBlock, style, $event.target.value)
                  "
                >
                  <option v-for="(c, i) in style.classes" :key="i" :value="i">
                    {{ c }}
                  </option>
                </select>
              </div>
              <div class="input-group" v-else>
                <input
                  type="checkbox"
                  v-model="values[is]"
                  v-on:change="
                    changeBlockStyle(selectedBlock, style, style.class)
                  "
                />
                <label>{{ style.name }}</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 mb-2">
              <h1>Margin</h1>
            </div>
            <div class="col-6">
              <div class="input-group">
                <label>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path
                      d="M21 3a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h18zm-1 2H4v14h16V5zm-2 2v2H6V7h12z"
                    />
                  </svg>
                </label>
                <select
                  v-model="values['top']"
                  v-on:change="
                    changeBlockStyle(
                      selectedBlock,
                      margin.top,
                      $event.target.value
                    )
                  "
                >
                  <option
                    v-for="(name, key) in margin.top.classes"
                    :key="key"
                    :value="key"
                  >
                    {{ name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-6">
              <div class="input-group">
                <label>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path
                      d="M21 3a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h18zm-1 2H4v14h16V5zm-2 10v2H6v-2h12z"
                    />
                  </svg>
                </label>
                <select
                  v-model="values['bottom']"
                  v-on:change="
                    changeBlockStyle(
                      selectedBlock,
                      margin.bottom,
                      $event.target.value
                    )
                  "
                >
                  <option
                    v-for="(name, key) in margin.bottom.classes"
                    :key="key"
                    :value="key"
                  >
                    {{ name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-6">
              <div class="input-group">
                <label>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path
                      d="M21 3a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h18zm-1 2H4v14h16V5zm-2 2v10h-2V7h2z"
                    />
                  </svg>
                </label>
                <select
                  v-model="values['left']"
                  v-on:change="
                    changeBlockStyle(
                      selectedBlock,
                      margin.left,
                      $event.target.value
                    )
                  "
                >
                  <option
                    v-for="(name, key) in margin.left.classes"
                    :key="key"
                    :value="key"
                  >
                    {{ name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-6">
              <div class="input-group">
                <label>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path
                      d="M21 3a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h18zm-1 2H4v14h16V5zM8 7v10H6V7h2z"
                    />
                  </svg>
                </label>
                <select
                  v-model="values['right']"
                  v-on:change="
                    changeBlockStyle(
                      selectedBlock,
                      margin.right,
                      $event.target.value
                    )
                  "
                >
                  <option
                    v-for="(name, key) in margin.right.classes"
                    :key="key"
                    :value="key"
                  >
                    {{ name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="white-box" v-for="(blocks, name) in content" :key="name">
          <div class="row align-items-center mb-3">
            <div class="col">
              <h1>{{ name }}</h1>
            </div>
            <div class="col-auto">
              <button
                v-on:click="addSubBlock(name)"
                v-if="blocks?.addMultiple !== false"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  fill="currentColor"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div
            class="card row"
            v-for="i in contentValues[name][Object.keys(contentValues[name])[0]]
              .length"
            :key="i"
          >
            <div class="col-3" v-on:click="editContent(name, i)">
              <span class="num" v-if="blocks?.addMultiple !== false">{{
                ordinal_suffix_of(i)
              }}</span>
              <span v-else>Edit {{ name }}</span>
            </div>
            <div class="col" v-on:click="editContent(name, i)">
              <div
                v-for="valType in content[name].values.filter(
                  (e) => e.type === 'text' || e.type === 'product'
                )"
                :key="valType.name"
              >
                <span v-if="valType.type === 'product'">
                  {{ contentValues[name][valType.name][i - 1]?.name }}
                </span>
                <span v-else-if="valType?.translates === true">
                  {{
                    contentValues[name][valType.name][i - 1][
                      activePage.language_code
                    ]?.substr(0, 30)
                  }}
                </span>
                <span v-else>
                  {{ contentValues[name][valType.name][i - 1].substr(0, 30) }}
                </span>
              </div>
            </div>
            <div class="col-3" v-on:click="editContent(name, i)">
              <img
                v-for="valType in content[name].values.filter(
                  (e) => e.type === 'image'
                )"
                :key="valType.name"
                :src="
                  typeof contentValues[name][valType.name][i - 1] === 'string'
                    ? contentValues[name][valType.name][i - 1]
                    : contentValues[name][valType.name][i - 1].src
                "
              />
            </div>
            <div class="col-auto">
              <button
                class="btn btn-transparent"
                v-on:click="deleteBlockItem(name, i)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                >
                  <path
                    d="M17 6h5v2h-2v13a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V8H2V6h5V3a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v3zm1 2H6v12h12V8zm-9 3h2v6H9v-6zm4 0h2v6h-2v-6zM9 4v2h6V4H9z"
                  />
                </svg>
              </button>
              <button
                class="btn btn-transparent"
                v-on:click="upBlockItem(name, i)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                  >
                    <path
                      d="M13.0001 7.82843V20H11.0001V7.82843L5.63614 13.1924L4.22192 11.7782L12.0001 4L19.7783 11.7782L18.3641 13.1924L13.0001 7.82843Z"
                    ></path>
                  </svg>
                </svg>
              </button>
              <button
                class="btn btn-transparent"
                v-on:click="downBlockItem(name, i)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                >
                  <path
                    d="M13.0001 16.1716L18.3641 10.8076L19.7783 12.2218L12.0001 20L4.22192 12.2218L5.63614 10.8076L11.0001 16.1716V4H13.0001V16.1716Z"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <AddBlock v-model:show="addBlockShow" v-on:addBlock="addBlock" />
  <EditContent
    v-model:show="editContentShow"
    v-model:name="editName"
    v-model:i="editI"
    v-model:items="content"
    v-model:values="contentValues"
    v-model:languages="languages"
    v-on:save="editContentChange(content)"
  />
  <GlobalSettings
    v-model:show="globalShettingsShow"
    v-model="globalVariables"
    v-on:change="changeStyle"
    v-on:importCss="addImportCss"
  />
</template>

<script>
import draggable from "vuedraggable";
import AddBlock from "../components/modals/AddBlock";
import GlobalSettings from "../components/modals/GlobalSettings";
import "../loading-bar";
import HeaderBlock from "../components/header";
import http from "../modules/http";
import EditContent from "../components/modals/EditContent";

export default {
  name: "BlockEditor",
  components: {
    EditContent,
    HeaderBlock,
    GlobalSettings,
    AddBlock,
    draggable,
  },
  watch: {
    size() {
      setTimeout(() => {
        this.calcRate();
      }, 1);
    },
  },
  data() {
    return {
      template: "vivienVance",
      size: "wide",
      rate: 1,
      activePage: {
        id: this.$route.params.id,
        language: "",
      },
      languages: [],
      height: undefined,
      values: {},
      content: {},
      contentValues: {},
      loaded: {
        script: false,
        style: false,
        var: 0,
        bar: undefined,
      },
      addBlockShow: false,
      globalShettingsShow: false,
      editContentShow: false,
      editName: "",
      editI: 0,
      globalVariables: [],
      selectedBlock: undefined,
      margin: {
        top: {
          classes: {
            "": "default",
            "mt-auto": "auto",
            "mt-1": "1x",
            "mt-2": "2x",
            "mt-3": "3x",
            "mt-4": "4x",
            "mt-5": "5x",
          },
        },
        bottom: {
          classes: {
            "": "default",
            "mb-auto": "auto",
            "mb-1": "1x",
            "mb-2": "2x",
            "mb-3": "3x",
            "mb-4": "4x",
            "mb-5": "5x",
          },
        },
        left: {
          classes: {
            "": "default",
            "ml-auto": "auto",
            "ml-1": "1x",
            "ml-2": "2x",
            "ml-3": "3x",
            "ml-4": "4x",
            "ml-5": "5x",
          },
        },
        right: {
          classes: {
            "": "default",
            "mr-auto": "auto",
            "mr-1": "1x",
            "mr-2": "2x",
            "mr-3": "3x",
            "mr-4": "4x",
            "mr-5": "5x",
          },
        },
      },
      blocks: [],
      possibleBlocks: [],
    };
  },
  computed: {
    mediaServer() {
      return process.env.VUE_APP_MEDIA_URL;
    },
  },
  mounted() {
    // eslint-disable-next-line no-undef
    this.loaded.bar = new ldBar(this.$refs.loadBar);
    this.loaded.var = 10;
    this.loaded.bar.set(this.loaded.var);

    this.loadBlocks();

    http.fetch(`/page/${this.activePage.id}`).then((data) => {
      this.activePage = data.data;
    });
    if (this.languages.length < 1) {
      http.fetch("/languages").then((data) => {
        this.languages = data;
      });
    }

    window.addEventListener("resize", this.getDimensions);

    let doc = this.$refs.preview.contentWindow.document;
    doc.write(`<!DOCTYPE html><html><head></head><body></body></html>`);

    fetch(`/templates/${this.template}/head.html`)
      .then((res) => {
        return res.text();
      })
      .then((data) => {
        doc.head.innerHTML += data;
        this.generateSass();
        this.loadPage();
      });
    this.calcRate();
    //this.refreshIframe();
  },
  unmounted() {
    window.removeEventListener("resize", this.getDimensions);
  },
  methods: {
    async loadBlocks() {
      const possibleBlocks = await import(
        `../../public/templates/${this.template}/blocks`
      );
      this.possibleBlocks = possibleBlocks.default;
    },
    ordinal_suffix_of(i) {
      let j = i % 10,
        k = i % 100;
      if (j === 1 && k !== 11) {
        return i + "st";
      }
      if (j === 2 && k !== 12) {
        return i + "nd";
      }
      if (j === 3 && k !== 13) {
        return i + "rd";
      }
      return i + "th";
    },
    upBlockItem(name, i) {
      let doc = this.$refs.preview.contentWindow.document;
      const blockDiv = doc.querySelector(
        `[data-block="${this.selectedBlock.blockID}"]`
      );
      if (blockDiv === null || blockDiv === undefined) {
        return;
      }

      const innerDoc = blockDiv.querySelector(
        this.content[name].ref
          ? `[data-ref="${this.content[name].ref}"]`
          : this.content[name].selector
      );

      if (innerDoc === null || innerDoc === undefined) {
        return;
      }

      const elementDoc = innerDoc.querySelectorAll(
        this.content[name].block.content[0].ref
          ? `[data-ref="${this.content[name].block.content[0].ref}"]`
          : this.content[name].block.content[0].selector
      );

      // Log the selected elements
      console.log("Selected elements:", elementDoc);

      if (i > 1 && elementDoc.length > 1) {
        const firstElement = elementDoc[i - 2];
        const secondElement = elementDoc[i - 1];

        // Log the elements to be swapped
        console.log("First element to swap:", firstElement);
        console.log("Second element to swap:", secondElement);

        const parent = firstElement.parentNode;
        const nextSibling = secondElement.nextSibling;
        console.log("nextSibling:", nextSibling);

        // Log the parent and next sibling
        console.log("Parent node:", parent);
        console.log("Next sibling:", nextSibling);

        // Swap the two elements
        parent.insertBefore(secondElement, firstElement);
        if (nextSibling) {
          parent.insertBefore(firstElement, nextSibling);
        } else {
          parent.appendChild(firstElement);
        }

        // Log after swapping
        console.log("Swapping completed.");

        let texts = this.activePage.translations[this.selectedBlock.blockID];

        if (texts !== undefined && texts !== null) {
          for (const it of Object.keys(texts)) {
            const text =
              this.activePage.translations[this.selectedBlock.blockID][it];
            const temp = text[i - 2];
            text[i - 2] = text[i - 1];
            text[i - 1] = temp;
          }
        }
      } else {
        // Log why swapping didn't happen
        console.log(
          "Swapping conditions not met. i:",
          i,
          "elements.length:",
          elementDoc.length
        );
      }

      this.showEditBlock(this.selectedBlock);
    },
    downBlockItem(name, i) {
      let doc = this.$refs.preview.contentWindow.document;
      const blockDiv = doc.querySelector(
        `[data-block="${this.selectedBlock.blockID}"]`
      );
      if (blockDiv === null || blockDiv === undefined) {
        return;
      }

      const innerDoc = blockDiv.querySelector(
        this.content[name].ref
          ? `[data-ref="${this.content[name].ref}"]`
          : this.content[name].selector
      );

      if (innerDoc === null || innerDoc === undefined) {
        return;
      }

      const elementDoc = innerDoc.querySelectorAll(
        this.content[name].block.content[0].ref
          ? `[data-ref="${this.content[name].block.content[0].ref}"]`
          : this.content[name].block.content[0].selector
      );

      // Log the selected elements
      console.log("Selected elements:", elementDoc);

      if (i < elementDoc.length + 1 && elementDoc.length > 1) {
        const firstElement = elementDoc[i - 1];
        const secondElement = elementDoc[i];

        // Log the elements to be swapped
        console.log("First element to swap:", firstElement);
        console.log("Second element to swap:", secondElement);

        const parent = firstElement.parentNode;
        const nextSibling = secondElement.nextSibling;
        console.log("nextSibling:", nextSibling);

        // Log the parent and next sibling
        console.log("Parent node:", parent);
        console.log("Next sibling:", nextSibling);

        // Swap the two elements
        parent.insertBefore(secondElement, firstElement);
        if (nextSibling) {
          parent.insertBefore(firstElement, nextSibling);
        } else {
          parent.appendChild(firstElement);
        }

        // Log after swapping
        console.log("Swapping completed.");

        let texts = this.activePage.translations[this.selectedBlock.blockID];

        if (texts !== undefined && texts !== null) {
          for (const it of Object.keys(texts)) {
            const text =
              this.activePage.translations[this.selectedBlock.blockID][it];
            const temp = text[i - 1];
            text[i - 1] = text[i];
            text[i] = temp;
          }
        }
      } else {
        // Log why swapping didn't happen
        console.log(
          "Swapping conditions not met. i:",
          i,
          "elements.length:",
          elementDoc.length
        );
      }

      this.showEditBlock(this.selectedBlock);
    },
    deleteBlockItem(name, i) {
      let doc = this.$refs.preview.contentWindow.document;
      const blockDiv = doc.querySelector(
        `[data-block="${this.selectedBlock.blockID}"]`
      );
      if (blockDiv === null || blockDiv === undefined) {
        return;
      }

      const innerDoc = blockDiv.querySelector(
        this.content[name].ref
          ? `[data-ref="${this.content[name].ref}"]`
          : this.content[name].selector
      );

      if (innerDoc === null || innerDoc === undefined) {
        return;
      }

      const elementDoc = innerDoc.querySelectorAll(
        this.content[name].block.content[0].ref
          ? `[data-ref="${this.content[name].block.content[0].ref}"]`
          : this.content[name].block.content[0].selector
      );

      elementDoc[i - 1].remove();
      this.showEditBlock(this.selectedBlock);
    },
    editContent(name, i) {
      this.editName = name;
      this.editI = i;
      this.editContentShow = true;
    },
    editContentChange() {
      let doc = this.$refs.preview.contentWindow.document;
      const blockDiv = doc.querySelector(
        '[data-block="' + this.selectedBlock.blockID + '"]'
      );

      for (const element of this.content[this.editName].values) {
        const valueDoc = blockDiv.querySelectorAll(
          element.ref ? `[data-ref="${element.ref}"]` : element.selector
        );
        if (
          element.type === "text" ||
          element.type === "long-text" ||
          element.type === "rich-text"
        ) {
          if (element?.translates === true) {
            if (
              this.activePage.translations[this.selectedBlock.blockID] ===
              undefined
            ) {
              this.activePage.translations[this.selectedBlock.blockID] = {};
            }
            if (
              this.activePage.translations[this.selectedBlock.blockID][
                element.name
              ] === undefined
            ) {
              this.activePage.translations[this.selectedBlock.blockID][
                element.name
              ] = {};
            }
            this.activePage.translations[this.selectedBlock.blockID][
              element.name
            ][this.editI - 1] =
              this.contentValues[this.editName][element.name][this.editI - 1];

            for (const lang in this.contentValues[this.editName][element.name][
              this.editI - 1
            ]) {
              if (lang === this.activePage.language_code) {
                valueDoc[this.editI - 1].innerHTML =
                  this.contentValues[this.editName][element.name][
                    this.editI - 1
                  ][lang];
              }
            }
          } else {
            valueDoc[this.editI - 1].innerHTML =
              this.contentValues[this.editName][element.name][this.editI - 1];
          }
        } else if (element.type === "image") {
          console.log(element);
          console.log(valueDoc[this.editI - 1]);
          console.log(
            this.contentValues[this.editName][element.name][this.editI - 1]
          );
          if (valueDoc[this.editI - 1].tagName === "SOURCE") {
            valueDoc[this.editI - 1].srcset =
              this.contentValues[this.editName][element.name][
                this.editI - 1
              ].src;
          } else {
            valueDoc[this.editI - 1].src =
              this.contentValues[this.editName][element.name][
                this.editI - 1
              ].src;
          }
          if (
            this.contentValues[this.editName][element.name][this.editI - 1]
              .sizes !== undefined
          ) {
            let sizes =
              this.contentValues[this.editName][element.name][this.editI - 1]
                .sizes;
            let p = [];
            for (const size of Object.keys(sizes)) {
              p.push(sizes[size] + " " + size + "w");
            }
            valueDoc[this.editI - 1].srcset = p.join(",");
            valueDoc[this.editI - 1].sizes = element.sizes(sizes);
          }
        } else if (element.type === "link") {
          valueDoc[this.editI - 1].href =
            this.contentValues[this.editName][element.name][this.editI - 1];
        } else if (element.type === "product") {
          let e =
            this.contentValues[this.editName][element.name][this.editI - 1];
          if (e.target_category_id !== undefined) {
            valueDoc[this.editI - 1].dataset.target_category_id =
              e.target_category_id;
            if (e.characteristics !== undefined) {
              valueDoc[this.editI - 1].dataset.characteristics =
                this.stringifyDataObject(e.characteristics);
            }
            if (e.tags !== undefined) {
              valueDoc[this.editI - 1].dataset.tags = e.tags.join(",");
            }
          } else {
            valueDoc[this.editI - 1].dataset.variant_id = e.variant_id;
          }
          if (e.target_sort !== undefined) {
            valueDoc[this.editI - 1].dataset.target_sort = e.target_sort;
          }
          valueDoc[this.editI - 1].getElementsByTagName("h3")[0].innerHTML =
            this.contentValues[this.editName][element.name][
              this.editI - 1
            ].name;
          valueDoc[this.editI - 1].getElementsByTagName("img")[0].src =
            this.mediaServer +
            "/" +
            this.contentValues[this.editName][element.name][this.editI - 1]
              .image;
        }
      }
    },
    loadPage() {
      this.blocks = [];
      let doc = this.$refs.preview.contentWindow.document;
      doc.body.innerHTML = "";
      http
        .fetch(`/page/${this.activePage.id}/html`, null, false, null, false)
        .then((data) => {
          const regex =
            /@include\(['|"]([^"']{1,200})['|"](,\s{0,4}\[([^\]]{1,400})]\))?/gm;
          let m;
          let needToFind = 0;
          let found = 0;

          let variantIds = [];

          while ((m = regex.exec(data)) !== null) {
            // This is necessary to avoid infinite loops with zero-width matches
            if (m.index === regex.lastIndex) {
              regex.lastIndex++;
            }
            {
              let dynamic = m[1];
              let block;
              if (dynamic === "common.product") {
                console.log(m);
                let vals = JSON.parse(
                  `{${m[3].replaceAll("'", '"').replaceAll("=>", ":")}}`
                );
                block = {
                  file: "product",
                  id: 99999,
                  classes: vals.class,
                };
                if (vals.target_category_id !== undefined) {
                  block["target_category_id"] = vals.target_category_id;
                  if (vals.characteristics !== undefined) {
                    block["characteristics"] = vals.characteristics;
                  }
                  if (vals.tags !== undefined) {
                    block["tags"] = vals.tags;
                  }
                } else {
                  block["variant_id"] = vals.variant_id;
                  variantIds.push(vals.variant_id);
                }
                if (vals.target_sort !== undefined) {
                  block["target_sort"] = vals.target_sort;
                }
              } else {
                block = this.possibleBlocks.find((e) => e.dynamic === dynamic);
              }
              needToFind++;
              let original = m[0];
              fetch(`/templates/${this.template}/blocks/${block.file}.html`)
                .then((res) => {
                  return res.text();
                })
                .then((txt) => {
                  let div = document.createElement("DIV");
                  div.innerHTML = txt;
                  div = div.firstChild;
                  div.dataset.block = `${block.id}-${Math.round(
                    Math.random() * 10000000000000
                  )}`;

                  if (
                    block.variant_id !== null &&
                    block.variant_id !== undefined
                  ) {
                    div.dataset.variant_id = block.variant_id;
                  }
                  if (
                    block.target_category_id !== null &&
                    block.target_category_id !== undefined
                  ) {
                    div.dataset.target_category_id = block.target_category_id;
                    div.getElementsByTagName("h3")[0].innerHTML =
                      "Multiple product";

                    if (
                      block.characteristics !== null &&
                      block.characteristics !== undefined
                    ) {
                      div.dataset.characteristics = block.characteristics;
                    }
                    if (
                        block.tags !== null &&
                        block.tags !== undefined
                    ) {
                      div.dataset.tags = block.tags;
                    }
                  }
                  if (block.classes !== null && block.classes !== undefined) {
                    let classes = block.classes.split(" ");
                    for (const class1 of classes) {
                      div.classList.add(class1);
                    }
                  }

                  data = data.replace(original, `${div.outerHTML}`);
                  found++;
                  this.loaded.var++;

                  if (needToFind === found) {
                    this.finishLoad(data);
                  }
                });
            }
            m;
          }

          http
            .fetch("/products/products/search", {
              id: variantIds,
            })
            .then((data) => {
              let doc = this.$refs.preview.contentWindow.document;
              for (const variant of data) {
                const blocks = doc.querySelectorAll(
                  `*[data-variant_id="${variant.variant_id}"]`
                );
                for (const block of blocks) {
                  block.dataset.variant_id = variant.variant_id;
                  block.getElementsByTagName("h3")[0].innerHTML = variant.name;
                  block.getElementsByTagName("img")[0].src =
                    this.mediaServer + "/" + variant.image;
                }
              }
            });

          if (needToFind === found) {
            this.finishLoad(data);
          }
        });
    },
    finishLoad(data) {
      let doc = this.$refs.preview.contentWindow.document;
      doc.body.innerHTML = data;
      const images = doc.getElementsByTagName("img");
      for (const image of images) {
        if (image.getAttribute("src")[0] === "/") {
          image.dataset.src = image.getAttribute("src");
          image.src = `/templates/${this.template}${image.getAttribute("src")}`;
        }
      }

      const blocks = doc.querySelectorAll("*[data-block]");
      for (const block of blocks) {
        const blockNumber = block.dataset.block.split("-");
        let blockInfo = this.possibleBlocks.find(
          (e) => e.id === Number.parseInt(blockNumber[0])
        );

        if (blockInfo !== undefined) {
          blockInfo = Object.assign({}, blockInfo);
          blockInfo.blockID = block.dataset.block;
          this.blocks.push(blockInfo);
        }
      }
    },
    setTranslation(raw, key, arr) {
      for (const rawKey in raw) {
        if (typeof raw[rawKey] === "string") {
          arr.push({
            key: key,
            value: raw[rawKey],
            language_code: rawKey,
          });
        } else {
          this.setTranslation(
            raw[rawKey],
            key.length > 0 ? key + "." + rawKey : rawKey,
            arr
          );
        }
      }
    },
    buildInclude(name,params) {
      const paramStrings = Object.entries(params)
          .map(([key, value]) => `'${key}'=>${typeof value === 'string' ? `'${value}'` : value}`)
          .join(', ');
      return `@include('${name}', [${paramStrings}])\n`;
    },
    save() {
      let pageData = [];
      this.loaded.var = 10;
      this.loaded.bar.set(this.loaded.var);
      this.loaded.script = false;
      let doc = this.$refs.preview.contentWindow.document;

      const images = doc.getElementsByTagName("img");
      for (const image of images) {
        if (image.dataset?.src !== undefined && images.dataset?.src !== null) {
          image.src = image.dataset.src;
          image.dataset.src = null;
        }
      }

      let el = doc.body,
        child = el.firstChild,
        nextChild;
      while (child) {
        nextChild = child.nextSibling;
        if (child.nodeType === 3 || child.nodeName === "SCRIPT") {
          el.removeChild(child);
        }
        child = nextChild;
      }

      for (const block of this.blocks) {
        const blockDiv = doc.querySelector(`[data-block="${block.blockID}"]`);
        if (blockDiv === null || blockDiv === undefined) {
          continue;
        }
        if (block.dynamic !== undefined && block.dynamic !== null) {
          if (block.content !== undefined) {
            this.getContent(block, blockDiv);
            blockDiv.replaceWith(
              '@include("' +
                block.dynamic +
                '", ' +
                JSON.stringify(this.contentValues)
                  .replaceAll("{", "[")
                  .replaceAll("}", "]") +
                ")"
            );

          } else {
            blockDiv.replaceWith("@include('" + block.dynamic + "')");
          }
        } else {
          this.changeTranslatableContent(
            block,
            blockDiv,
            "{!! __('[[i]]') !!}"
          );
        }

        this.content = {};
        this.contentValues = {};
        this.getContent(block, doc);
        pageData.push({
          block: block.file,
          values: Object.keys(this.contentValues).length === 0 ? null : this.contentValues,
        });
      }

      this.contentValues = {};
      this.content = {};
      this.selectedBlock = undefined;


      // remove backgroud
      let bgImages = doc.getElementsByClassName("cover-img");
      for (const bgImage of bgImages) {
        bgImage.style = "";
      }

      // replace products
      let products = doc.getElementsByClassName("product");
      while (products.length > 0) {
        let product = products[0];
        let classes = product.className;

        if (
            product.dataset.target_category_id !== undefined ||
            product.dataset.target_sort !== undefined ||
            product.dataset.characteristics !== undefined ||
            product.dataset.tags !== undefined
        ) {
          let parameters = {};

          if (product.dataset.target_category_id !== undefined){
            parameters["target_category_id"] = product.dataset.target_category_id;
          }
          if (product.dataset.target_sort !== undefined){
            parameters["target_sort"] = product.dataset.target_sort;
          }
          if (product.dataset.characteristics !== undefined){
            parameters["characteristics"] = product.dataset.characteristics;
          }
          if (product.dataset.tags !== undefined){
            parameters["tags"] = product.dataset.tags;
          }

          parameters["class"] = classes;

          product.replaceWith(this.buildInclude('common.product',parameters));
        } else {
          let variantid = product.dataset.variant_id;
          product.replaceWith(
            "@include('common.product', ['variant_id'=>" +
              variantid +
              ",'class'=>'" +
              classes +
              "'])\n"
          );
        }
      }

      this.loaded.var = 40;
      this.loaded.bar.set(this.loaded.var);

      let transArray = [];
      this.setTranslation(this.activePage.translations, "", transArray);
      http.fetch(`/page/${this.activePage.id}/translations`, {
        data: transArray,
      });

      http
        .fetch(
          `/page/${this.activePage.id}`,
          {
            json: JSON.stringify(pageData),
            html: this.$refs.preview.contentWindow.document.body.innerHTML.replaceAll(
              "\n\n",
              "\n"
            ),
          },
          true,
          "PUT"
        )
        .then(() => {
          this.loadPage();
          this.loaded.var = 90;
          this.loaded.bar.set(this.loaded.var);
          this.loaded.script = true;
        });
    },
    getDimensions() {
      this.calcRate();
    },
    addBlock(block) {
      this.blocks.push(block);
      this.refreshIframe();
    },
    calcRate() {
      let max = this.$refs.outsideContainer.offsetWidth - 20;
      let actual = this.$refs.container.offsetWidth;
      if (max < actual) {
        this.rate = max / actual;
        if (this.size === "wide") {
          this.height =
            this.$refs.outsideContainer.offsetHeight * (1 / this.rate);
        } else {
          this.height = undefined;
        }
      } else {
        this.rate = 1;
      }
    },
    moveEvent() {
      this.refreshIframe();
    },
    refreshIframe() {
      let list = [];
      list = list.concat(this.blocks);

      this.renderBlock(0, list);
    },
    changeTranslatableContent(
      block,
      doc,
      replaceTo,
      seti = true,
      start = true
    ) {
      if (start && seti) {
        replaceTo = replaceTo.replace("[[i]]", block.blockID + ".[[i]]");
      }
      if (block.content !== undefined) {
        for (const content of block.content) {
          const innerDoc = doc.querySelector(
            content.ref ? `[data-ref="${content.ref}"]` : content.selector
          );
          if (content.blocks !== undefined) {
            for (const innerBlock of content.blocks) {
              if (seti) {
                this.changeTranslatableContent(
                  innerBlock,
                  innerDoc,
                  replaceTo, //.replace("[[i]]", content.name + ".[[i]]"),
                  seti,
                  false
                );
              } else {
                this.changeTranslatableContent(
                  innerBlock,
                  innerDoc,
                  replaceTo,
                  seti,
                  false
                );
              }
            }
          } else if (content.values !== undefined) {
            for (const valueData of content.values) {
              const valueDoc = doc.querySelectorAll(
                valueData.ref
                  ? `[data-ref="${valueData.ref}"]`
                  : valueData.selector
              );
              if (valueData.translates === true) {
                let i = 0;
                for (const valueDocElement of valueDoc) {
                  if (seti) {
                    valueDocElement.innerHTML = replaceTo.replace(
                      "[[i]]",
                      valueData.name + "." + i
                    );
                  } else {
                    valueDocElement.innerHTML = replaceTo;
                  }
                  i++;
                }
              }
            }
          }
        }
      }
    },
    parseDataString(dataString) {
      const result = {};
      const entries = dataString.split("|");

      entries.forEach((entry) => {
        const [key, valueStr] = entry.split(":");
        const values = valueStr.split(",").map(Number);
        result[Number(key)] = values;
      });

      return result;
    },
    stringifyDataObject(dataObject) {
      let result = "";

      for (const key in dataObject) {
        if (Object.hasOwnProperty.call(dataObject, key)) {
          if (result !== "") {
            result += "|";
          }
          result += key + ":" + dataObject[key].join(",");
        }
      }

      return result;
    },
    getContent(block, doc, blockID = null, selector = null) {
      if (block.content !== undefined) {
        for (const content of block.content) {
          let innerDoc;
          if (content.ref === undefined && content.selector === undefined) {
            innerDoc = doc;
          } else {
            innerDoc = doc.querySelector(
              content.ref ? `[data-ref="${content.ref}"]` : content.selector
            );
          }
          if (content.blocks !== undefined) {
            for (const innerBlock of content.blocks) {
              this.getContent(
                innerBlock,
                innerDoc,
                block.blockID,
                content.selector
              );
            }
          } else if (content.values !== undefined) {
            this.content[content.name] = {
              values: content.values,
              block: block,
              addMultiple: content?.addMultiple,
              selector: selector ?? block.selector,
            };
            this.contentValues[content.name] = {};
            for (const valueData of content.values) {
              console.log(valueData);
              this.contentValues[content.name][valueData.name] = [];
              let valueDoc;
              if (
                valueData.ref === undefined &&
                valueData.selector === undefined
              ) {
                valueDoc = doc;
              } else {
                valueDoc = doc.querySelectorAll(
                  valueData.ref
                    ? `[data-ref="${valueData.ref}"]`
                    : valueData.selector
                );
              }
              console.log(valueDoc);

              let i = 0;
              for (const valueDocElement of valueDoc) {
                let val = undefined;
                if (
                  valueData.type === "text" ||
                  valueData.type === "long-text" ||
                  valueData.type === "rich-text"
                ) {
                  val = valueDocElement.innerHTML;
                } else if (valueData.type === "image") {
                  if (
                    valueDocElement.srcset !== undefined &&
                    valueDocElement.srcset !== null
                  ) {
                    let sizes = valueDocElement.srcset.split(",");
                    val = {
                      src: valueDocElement.src,
                      sizes: {},
                    };
                    let src = null;
                    for (const size of sizes) {
                      const parts = size.split(" ");
                      if (parts.length === 2) {
                        val.sizes[parts[1].replace("w", "")] = parts[0];
                        src = parts[0];
                      }
                    }
                    if (
                      val.src === undefined ||
                      val.src === null ||
                      val.src.length < 1
                    ) {
                      val.src = src;
                    }
                  } else {
                    val = valueDocElement.src ?? valueDocElement.dataset.src;
                  }
                } else if (valueData.type === "link") {
                  val = valueDocElement.href ?? valueDocElement.dataset.href;
                  val = val.replace(
                    location.protocol + "//" + location.host,
                    ""
                  );
                } else if (valueData.type === "product") {
                  val = {
                    image: valueDocElement
                      .getElementsByTagName("img")[0]
                      .src.replace(this.mediaServer, ""),
                    name: valueDocElement.getElementsByTagName("h3")[0]
                      .innerHTML,
                  };
                  if (
                    valueDocElement.dataset.target_category_id !== undefined
                  ) {
                    val["target_category_id"] = Number.parseInt(
                      valueDocElement.dataset.target_category_id
                    );
                    if (valueDocElement.dataset.characteristics !== undefined) {
                      val["characteristics"] = this.parseDataString(
                        valueDocElement.dataset.characteristics
                      );
                    }
                    if (valueDocElement.dataset.tags !== undefined) {
                      val["tags"] = valueDocElement.dataset.tags.split(",");
                    }
                  } else {
                    val["variant_id"] = valueDocElement.dataset.variant_id;
                  }
                  if (valueDocElement.dataset.target_sort !== undefined) {
                    val["target_sort"] = valueDocElement.dataset.target_sort;
                  }
                }
                if (valueData?.translates === true) {
                  if (
                    this.activePage.translations[blockID ?? block.blockID] ===
                    undefined
                  ) {
                    val = {};
                  } else if (
                    this.activePage.translations[blockID ?? block.blockID][
                      valueData.name
                    ] === undefined
                  ) {
                    val = {};
                  } else if (
                    this.activePage.translations[blockID ?? block.blockID][
                      valueData.name
                    ][i] === undefined
                  ) {
                    val = {};
                  } else {
                    val =
                      this.activePage.translations[blockID ?? block.blockID][
                        valueData.name
                      ][i];
                  }
                }

                console.log(this.contentValues[content.name][valueData.name]);
                this.contentValues[content.name][valueData.name].push(val);
                i++;
              }
            }
          }
        }
      }
    },
    showEditBlock(block) {
      this.selectedBlock = block;
      this.values = { left: "", right: "", top: "", bottom: "" };

      let doc = this.$refs.preview.contentWindow.document;
      const blockDiv = doc.querySelector(`[data-block="${block.blockID}"]`);
      if (blockDiv === null || blockDiv === undefined) {
        return;
      }

      for (const margin in this.margin) {
        for (const className in this.margin[margin].classes) {
          if (blockDiv.classList.contains(className)) {
            this.values[margin] = className;
          }
        }
      }

      this.content = {};
      this.getContent(block, blockDiv);

      if (block.styles === undefined) {
        return;
      }

      for (const i in block.styles) {
        const style = block.styles[i];
        const ref =
          style.ref || style.selector
            ? blockDiv.querySelector(
                style.ref ? `[data-ref="${style.ref}"]` : style.selector
              )
            : [blockDiv];

        if (style.classes !== undefined) {
          for (const className in style.classes) {
            if (ref.classList?.contains(className)) {
              this.values[i] = className;
            }
          }
        } else if (style.class !== undefined) {
          if (ref.classList?.contains(style.class)) {
            this.values[i] = true;
          }
        }
      }
    },
    addSubBlock(name) {
      let doc = this.$refs.preview.contentWindow.document;
      const blockDiv = doc.querySelector(
        `[data-block="${this.selectedBlock.blockID}"]`
      );
      if (blockDiv === null || blockDiv === undefined) {
        return;
      }

      const innerDoc = blockDiv.querySelector(
        this.content[name].ref
          ? `[data-ref="${this.content[name].ref}"]`
          : this.content[name].selector
      );

      if (innerDoc === null || innerDoc === undefined) {
        return;
      }

      this.renderBlock(0, [this.content[name].block], innerDoc, () => {
        this.content = {};
        this.getContent(this.selectedBlock, doc);
      });
    },
    renderBlock(i, list, doc = null, after = null) {
      if (doc === null) {
        doc = this.$refs.preview.contentWindow.document.body;
      }

      if (i >= list.length) {
        let script = document.createElement("script");
        script.innerHTML = `dispatchEvent(new Event("DOMContentLoaded"));dispatchEvent(new Event("load"));`;
        doc.appendChild(script);
        return;
      }

      let blockID =
        list[i].id + "-" + Math.round(Math.random() * 10000000000000);
      if (
        i > 0 &&
        (list[i].blockID === undefined || list[i].blockID === null)
      ) {
        list[i].blockID = blockID;
      }
      const blockDiv = doc.querySelector(`[data-block="${list[i].blockID}"]`);
      if (blockDiv !== undefined && blockDiv !== null) {
        let div = blockDiv.cloneNode(true);
        blockDiv.remove();
        doc.appendChild(div);
        this.renderBlock(i + 1, list);
        if (after !== null) {
          after();
        }
        return;
      }

      if (list[i].file !== undefined) {
        fetch(`/templates/${this.template}/blocks/${list[i].file}.html`)
          .then((res) => {
            return res.text();
          })
          .then((data) => {
            let div = document.createElement("DIV");
            div.innerHTML = data;
            div = div.firstChild;
            div.dataset.block = blockID;
            doc.appendChild(div);
            this.renderBlock(i + 1, list);
            if (after !== null) {
              after();
            }
          });
      } else {
        let div = document.createElement("DIV");
        div.innerHTML = list[i].name;
        div.dataset.block = blockID;
        doc.body.appendChild(div);
        this.renderBlock(i + 1, list);
      }
    },
    deleteBlock() {
      let doc = this.$refs.preview.contentWindow.document;
      const blockDiv = doc.querySelector(
        `[data-block="${this.selectedBlock.blockID}"]`
      );
      if (blockDiv === null || blockDiv === undefined) {
        return;
      }
      blockDiv.remove();
      let i = this.blocks.findIndex(
        (e) => e.blockID === this.selectedBlock.blockID
      );
      this.blocks.splice(i, 1);
    },
    changeBlockStyle(block, style, className) {
      let doc = this.$refs.preview.contentWindow.document;
      const blockDiv = doc.querySelector(`[data-block="${block.blockID}"]`);
      if (blockDiv === null || blockDiv === undefined) {
        return;
      }
      const refs =
        style.ref || style.selector
          ? blockDiv.querySelectorAll(
              style.ref ? `[data-ref="${style.ref}"]` : style.selector
            )
          : [blockDiv];

      for (const ref of refs) {
        if (style.classes !== undefined) {
          for (const classRemove in style.classes) {
            if (ref.classList.contains(classRemove)) {
              ref.classList.remove(classRemove);
            }
          }
        }
        if (ref.classList.contains(className)) {
          ref.classList.remove(className);
        } else {
          ref.classList.add(className);
        }
      }
    },
    setCss(css) {
      this.loaded.var = 98;
      this.loaded.bar.set(this.loaded.var);

      let doc = this.$refs.preview.contentWindow.document;
      let div = document.createElement("STYLE");
      div.innerHTML = css;
      doc.head.appendChild(div);

      this.loaded.style = true;
      this.loaded.var = 99;
      this.loaded.bar.set(this.loaded.var);

      const _this = this;
      if (!_this.loaded.script) {
        let doc = _this.$refs.preview.contentWindow.document;

        const script2 = document.createElement("script");
        script2.src = "/readyState.js";
        script2.onload = function () {
          const script = document.createElement("script");
          script.onload = function () {
            _this.loaded.script = true;
          };
          script.src = "https://vivienvance.com/js/app.js";
          doc.head.appendChild(script);
        };
        doc.head.appendChild(script2);
      }
    },
    changeGlobalSettingsModal() {
      this.getGlobalSettings().then((variables) => {
        this.globalVariables = variables;
        this.globalShettingsShow = true;
      });
    },
    addImportCss(src) {
      let doc = this.$refs.preview.contentWindow.document;
      const style = document.createElement("style");
      style.innerText = `@import url('${src}');`;
      doc.head.appendChild(style);
    },
    getGlobalSettings() {
      return new Promise((resolve) => {
        fetch(`/templates/${this.template}/css/variables.scss`)
          .then((res) => {
            return res.text();
          })
          .then((scss) => {
            let colors = scss.matchAll(
              /\$([\S]{0,100})[\s]{0,4}:[\s]{0,4}(#[0-9ABCDEF]{3,6}|rgb[a]{0,1}\([0-9,.;% ]{3,30}\))/gim
            );
            let everyVariables = scss.matchAll(
              /\$([\S]{0,100})[\s]{0,4}:[\s]{0,4}([^\n;]{0,100})[\s;]{0,4}(\/\*[\s]{0,4}([^/]{0,100})[\s]{0,4}\*\/){0,1}/gim
            );

            let variables = {};
            let types = ["font-family", "font-size", "line-height"];

            for (const match of colors) {
              variables[match[1]] = { value: match[2], type: "color" };
            }
            for (const match of everyVariables) {
              if (variables[match[1]] === undefined) {
                let type = undefined;
                if (match[4] !== undefined && types.indexOf(match[4]) > -1) {
                  type = match[4];
                  match[2] = match[2].replace("px", "");
                }
                variables[match[1]] = { value: match[2], type: type };
              }
            }
            resolve(variables);
          });
      });
    },
    changeStyle(key, val) {
      let doc =
        this.$refs.preview.contentWindow.document.querySelector(":root");
      doc.style.setProperty("--" + key, val);
    },
    generateSass() {
      const Sass = require("../sass-js/sass.js");

      Sass.setWorkerUrl("/sass-js/sass.worker.js");

      const sass = new Sass();
      const _this = this;

      sass.importer(function (request, done) {
        if (_this.loaded.var > 98) {
          _this.loaded.var = 99;
        } else if (_this.loaded.var > 75) {
          _this.loaded.var += 1;
        } else {
          _this.loaded.var += 5;
        }
        _this.loaded.bar.set(_this.loaded.var);
        if (request.path) {
          done();
        } else if (request.current === "variables") {
          _this.getGlobalSettings().then((variables) => {
            let rootCss = ":root {\n";
            let varCss = "";
            for (const key in variables) {
              rootCss += `--${key}: ${variables[key].value};\n`;
              varCss += `$${key}: var(--${key});\n`;
            }
            rootCss += "}";
            done({ content: rootCss + " " + varCss });
          });
        } else {
          fetch(`/templates/${_this.template}/css/${request.current}.scss`)
            .then((res) => {
              return res.text();
            })
            .then((scss) => {
              if (scss.indexOf("<html") > -1) {
                fetch(`/templates/${_this.template}/css/${request.current}.css`)
                  .then((res) => {
                    return res.text();
                  })
                  .then((scss) => {
                    done({ content: scss });
                  });
              } else {
                done({ content: scss });
              }
            });
        }
      });

      fetch(`/templates/${_this.template}/css/app.scss`)
        .then((res) => {
          return res.text();
        })
        .then((scss) => {
          sass.compile(scss, function (result) {
            _this.setCss(result.text);
          });
        });
      fetch(`/templates/${_this.template}/css/critical.scss`)
        .then((res) => {
          return res.text();
        })
        .then((scss) => {
          sass.compile(scss, function (result) {
            _this.setCss(result.text);
          });
        });
    },
  },
};
</script>
