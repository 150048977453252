<template>
  <div
    ref="modal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-xl">
      <div class="modal-content" v-if="show">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Edit content</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            v-on:click="$emit('update:show', false)"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"
              />
            </svg>
          </button>
        </div>
        <div class="modal-body" v-if="name">
          <div
            v-for="item in items[name].values"
            :key="item.name"
            class="row my-2"
            :class="item.type !== 'rich-text' ? 'form-group' : 'rich-text'"
            :style="
              item.type === 'rich-text' ? 'margin-bottom: 55px!important' : ''
            "
          >
            <div class="col-12" v-if="item.type === 'rich-text' && !refresh">
              <label>
                {{ item.name }}
              </label>
              <div
                :ref="'edit-' + item.name"
                v-html="
                  item?.translates === true
                    ? valuesData[name][item.name][i - 1][this.language]
                    : valuesData[name][item.name][i - 1]
                "
              ></div>
            </div>
            <div class="col-12" v-else-if="item.type === 'long-text'">
              <label :for="'input' + item.name" class="col-sm-5 col-form-label">
                {{ item.name }}
              </label>
              <textarea
                v-if="item?.translates === true"
                type="text"
                class="form-control"
                :id="'input' + item.name"
                v-model="valuesData[name][item.name][i - 1][this.language]"
              ></textarea>
              <textarea
                v-else
                type="text"
                class="form-control"
                :id="'input' + item.name"
                v-model="valuesData[name][item.name][i - 1]"
              ></textarea>
            </div>
            <div class="col-12 row" v-else-if="item.type === 'image'">
              <label :for="'input' + item.name" class="col-sm-5 col-form-label">
                {{ item.name }}
              </label>
              <div class="col-sm-7">
                <input
                  type="file"
                  :ref="'file_' + btoa(name + item.name + i)"
                  :id="'file_' + btoa(name + item.name + i)"
                  v-on:change="upload(name, item.name, i)"
                  style="display: none"
                />
                <div class="block-list c-4">
                  <div>
                    <img
                      v-if="
                        valuesData[name][item.name][i - 1] !== undefined &&
                        valuesData[name][item.name][i - 1] !== null
                      "
                      alt=""
                      class="w-100"
                      :src="
                        typeof valuesData[name][item.name][i - 1] === 'string'
                          ? valuesData[name][item.name][i - 1]
                          : valuesData[name][item.name][i - 1].src
                      "
                    />
                  </div>
                  <div class="block">
                    <button
                      v-on:click="
                        valuesData[name][item.name][i - 1].edit = 'upload';
                        startUpload(btoa(name + item.name + i));
                      "
                    >
                      <div class="block-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                        >
                          <path
                            d="M12 12.5858L16.2426 16.8284L14.8284 18.2426L13 16.415V22H11V16.413L9.17157 18.2426L7.75736 16.8284L12 12.5858ZM12 2C15.5934 2 18.5544 4.70761 18.9541 8.19395C21.2858 8.83154 23 10.9656 23 13.5C23 16.3688 20.8036 18.7246 18.0006 18.9776L18.0009 16.9644C19.6966 16.7214 21 15.2629 21 13.5C21 11.567 19.433 10 17.5 10C17.2912 10 17.0867 10.0183 16.8887 10.054C16.9616 9.7142 17 9.36158 17 9C17 6.23858 14.7614 4 12 4C9.23858 4 7 6.23858 7 9C7 9.36158 7.03838 9.7142 7.11205 10.0533C6.91331 10.0183 6.70879 10 6.5 10C4.567 10 3 11.567 3 13.5C3 15.2003 4.21241 16.6174 5.81986 16.934L6.00005 16.9646L6.00039 18.9776C3.19696 18.7252 1 16.3692 1 13.5C1 10.9656 2.71424 8.83154 5.04648 8.19411C5.44561 4.70761 8.40661 2 12 2Z"
                          ></path>
                        </svg>
                      </div>
                      <div class="block-name">Upload</div>
                    </button>
                  </div>
                  <div class="block">
                    <button
                      v-on:click="
                        valuesData[name][item.name][i - 1].edit = 'search'
                      "
                    >
                      <div class="block-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                        >
                          <path
                            d="M11 2C15.968 2 20 6.032 20 11C20 15.968 15.968 20 11 20C6.032 20 2 15.968 2 11C2 6.032 6.032 2 11 2ZM11 18C14.8675 18 18 14.8675 18 11C18 7.1325 14.8675 4 11 4C7.1325 4 4 7.1325 4 11C4 14.8675 7.1325 18 11 18ZM19.4853 18.0711L22.3137 20.8995L20.8995 22.3137L18.0711 19.4853L19.4853 18.0711Z"
                          ></path>
                        </svg>
                      </div>
                      <div class="block-name">Search</div>
                    </button>
                  </div>
                  <div class="block">
                    <button
                      v-on:click="
                        browseImages(item.name);
                        valuesData[name][item.name][i - 1].edit = 'browse';
                      "
                    >
                      <div class="block-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                        >
                          <path
                            d="M6 7V4C6 3.44772 6.44772 3 7 3H13.4142L15.4142 5H21C21.5523 5 22 5.44772 22 6V16C22 16.5523 21.5523 17 21 17H18V20C18 20.5523 17.5523 21 17 21H3C2.44772 21 2 20.5523 2 20V8C2 7.44772 2.44772 7 3 7H6ZM6 9H4V19H16V17H6V9ZM8 5V15H20V7H14.5858L12.5858 5H8Z"
                          ></path>
                        </svg>
                      </div>
                      <div class="block-name">Browse</div>
                    </button>
                  </div>
                </div>
                <div
                  v-if="valuesData[name][item.name][i - 1].edit === 'search'"
                >
                  <v-select
                    v-model="valuesData[name][item.name][i - 1]"
                    v-model:options="images[item.name]"
                    v-on:search="(a, e) => fetchImages(a, e, item.name)"
                    :filterable="false"
                    :reduce="(c) => getImageLinks(c)"
                    placeholder="Select image"
                  >
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching && search.length > 2">
                        No item found for <em>{{ search }}</em
                        >.
                      </template>
                      <span v-else> Start typing to search</span>
                    </template>
                    <template v-slot:selected-option="option">
                      <img :src="getImageLinks(option).src" width="80" alt="" />
                      <span>{{ option.name }}</span>
                    </template>
                    <template v-slot:option="option">
                      <img
                        :src="
                          mediaServer +
                          '/' +
                          option.path +
                          '/' +
                          option.name +
                          '.webp'
                          // option.format
                        "
                        width="80"
                        alt=""
                      />
                      <span>{{ option.name }}</span>
                    </template>
                  </v-select>
                </div>
                <div
                  v-if="valuesData[name][item.name][i - 1].edit === 'upload'"
                >
                  <div v-if="uploadProccess" class="status-bar mt-1">
                    <span
                      class="status-bar-process"
                      :style="`width: ${uploadPercent}%`"
                    ></span>
                  </div>
                </div>
                <div
                  v-if="valuesData[name][item.name][i - 1].edit === 'browse'"
                >
                  <div class="my-3">
                    <button
                      class="btn btn-outline-primary"
                      v-on:click="browseImages(item.name, 2)"
                    >
                      Category
                    </button>
                    <button
                      class="btn btn-outline-primary"
                      v-on:click="browseImages(item.name, 3)"
                    >
                      Cover
                    </button>
                    <button
                      class="btn btn-outline-primary"
                      v-on:click="browseImages(item.name, 4)"
                    >
                      Color
                    </button>
                    <button
                      class="btn btn-outline-primary"
                      v-on:click="browseImages(item.name, 5)"
                    >
                      Icon
                    </button>
                  </div>
                  <div class="block-list c-4 browse-img">
                    <div v-for="img in images[item.name]" :key="img.id">
                      <img
                        v-on:click="
                          valuesData[name][item.name][i - 1] =
                            getImageLinks(img)
                        "
                        loading="lazy"
                        :src="
                          mediaServer +
                          '/' +
                          img.path +
                          (img.type < 4 ? '/480-' : '/128-') +
                          img.name +
                          '.webp'
                          // option.format
                        "
                        width="128"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 row" v-else-if="item.type === 'product'">
              <label :for="'input' + item.name" class="col-sm-5 col-form-label">
                {{ item.name }}
              </label>
              <div class="col-sm-7">
                <div class="block-list c-3">
                  <div class="block">
                    <button v-on:click="productSelector = 'product'">
                      <div class="block-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                        >
                          <path
                            d="M15.5 5C13.567 5 12 6.567 12 8.5C12 10.433 13.567 12 15.5 12C17.433 12 19 10.433 19 8.5C19 6.567 17.433 5 15.5 5ZM10 8.5C10 5.46243 12.4624 3 15.5 3C18.5376 3 21 5.46243 21 8.5C21 9.6575 20.6424 10.7315 20.0317 11.6175L22.7071 14.2929L21.2929 15.7071L18.6175 13.0317C17.7315 13.6424 16.6575 14 15.5 14C12.4624 14 10 11.5376 10 8.5ZM3 4H8V6H3V4ZM3 11H8V13H3V11ZM21 18V20H3V18H21Z"
                          ></path>
                        </svg>
                      </div>
                      <div class="block-name">Product</div>
                    </button>
                  </div>
                  <div class="block">
                    <button
                      v-on:click="
                        changeToFilter(name, item.name, i);
                        productSelector = 'filter';
                      "
                    >
                      <div class="block-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                        >
                          <path
                            d="M21 4V6H20L15 13.5V22H9V13.5L4 6H3V4H21ZM6.4037 6L11 12.8944V20H13V12.8944L17.5963 6H6.4037Z"
                          ></path>
                        </svg>
                      </div>
                      <div class="block-name">Filter</div>
                    </button>
                  </div>
                </div>
                <div v-if="productSelector === 'product'">
                  <v-select
                    v-model="valuesData[name][item.name][i - 1]"
                    v-model:options="products"
                    v-on:search="fetchProducts"
                    :filterable="false"
                    placeholder="Select product"
                  >
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching && search.length > 3">
                        No products found for <em>{{ search }}</em
                        >.
                      </template>
                      <span v-else>Start typing to search for a product</span>
                    </template>
                    <template v-slot:selected-option="option">
                      <div class="row">
                        <div class="col-auto px-0">
                          <img
                            :src="mediaServer + '/' + option?.image"
                            v-if="
                              option?.image !== null &&
                              option?.image !== undefined
                            "
                            class="product-image"
                            alt=""
                          />
                          <BaseIcon
                            v-else
                            name="no-image"
                            class="product-image"
                          />
                        </div>
                        <div class="col">
                          <div>
                            <b>{{ option.name }}</b>
                          </div>
                          <div>
                            <span
                              v-for="char in option.caracteristics"
                              :key="char"
                              class="me-2"
                            >
                              {{ char }}
                            </span>
                            <span class="me-2">
                              {{ option.serial }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </template>
                    <template v-slot:option="option">
                      <b>{{ option.name }}</b>
                      <span
                        v-for="char in option.caracteristics"
                        :key="char"
                        class="ms-2"
                      >
                        {{ char }}
                      </span>
                      <span class="ms-2">
                        {{ option.serial }}
                      </span>
                    </template>
                  </v-select>
                </div>
                <div v-if="productSelector === 'filter'">
                  <v-select
                    v-model="
                      valuesData[name][item.name][i - 1].target_category_id
                    "
                    v-model:options="categories"
                    :filter="
                      (s, q) =>
                        s.filter((e) => e.name.toLowerCase().indexOf(q) > -1)
                    "
                    placeholder="Select category"
                    :reduce="(c) => c.target_category_id"
                  >
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching && search.length > 1">
                        No products found for <em>{{ search }}</em
                        >.
                      </template>
                      <span v-else>Start typing to search for a product</span>
                    </template>
                    <template v-slot:selected-option="option">
                      <div class="row">
                        <div class="col-auto px-0">
                          <img
                            :src="mediaServer + '/' + option?.image"
                            v-if="
                              option?.image !== null &&
                              option?.image !== undefined
                            "
                            class="product-image"
                            alt=""
                          />
                          <BaseIcon
                            v-else
                            name="no-image"
                            class="product-image"
                          />
                        </div>
                        <div class="col">
                          <div>
                            <b>{{ option.name }}</b>
                          </div>
                          <div>
                            <span
                              v-for="char in option.caracteristics"
                              :key="char"
                              class="me-2"
                            >
                              {{ char }}
                            </span>
                            <span class="me-2">
                              {{ option.serial }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </template>
                    <template v-slot:option="option">
                      <b>{{ option.name }}</b>
                      <span
                        v-for="char in option.caracteristics"
                        :key="char"
                        class="ms-2"
                      >
                        {{ char }}
                      </span>
                      <span class="ms-2">
                        {{ option.serial }}
                      </span>
                    </template>
                  </v-select>
                  <h3>Tags</h3>
                  <div class="filters mt-1 mb-2">
                    <div v-for="tag in tags" :key="tag.id">
                      <label>
                        <input
                          type="checkbox"
                          :value="tag.id"
                          v-model="valuesData[name][item.name][i - 1].tags"
                        />
                        {{ tag.name }}
                      </label>
                    </div>
                  </div>

                  <div v-for="ch in characteristics" :key="ch.id">
                    <h3>{{ ch.name }}</h3>
                    <div class="filters mt-1 mb-2">
                      <div
                        v-for="val in ch.characteristics_values"
                        :key="val.id"
                      >
                        <label>
                          <input
                            type="checkbox"
                            :value="val.id"
                            v-model="
                              valuesData[name][item.name][i - 1]
                                .characteristics[ch.id]
                            "
                          />
                          {{ val.value }}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 row" v-else-if="item.type === 'video'">
              <div class="col-sm-5 col-form-label">
                {{ item.name }}
              </div>
              <div class="col-sm-7">
                <button
                  class="btn btn-primary"
                  v-on:click="showVideoModal = true"
                >
                  Select or upload video
                </button>
              </div>
            </div>
            <div class="col-12 row" v-else-if="item.type === 'link'">
              <label :for="'input' + item.name" class="col-sm-5 col-form-label">
                {{ item.name }}
              </label>
              <div class="col-sm-7">
                <v-select
                  v-model="valuesData[name][item.name][i - 1]"
                  :options="links"
                  :reduce="(c) => getLink(c)"
                  placeholder="Select one"
                >
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching && search.length > 2">
                      No item found for <em>{{ search }}</em
                      >.
                    </template>
                    <span v-else> Start typing to search</span>
                  </template>
                  <template v-slot:selected-option="option">
                    <span>{{ option.name }}</span>
                    <span class="text-grey" v-if="option.target_category_id">
                      Category
                    </span>
                    <span class="text-grey" v-if="option.target_page_id">
                      Page
                    </span>
                  </template>
                  <template v-slot:option="option">
                    <span>{{ option.name }}</span>
                    <span class="text-grey" v-if="option.target_category_id">
                      Category
                    </span>
                    <span class="text-grey" v-if="option.target_page_id">
                      Page
                    </span>
                  </template>
                </v-select>
              </div>
            </div>
            <div class="col-12 row" v-else>
              <label :for="'input' + item.name" class="col-sm-5 col-form-label">
                {{ item.name }}
              </label>
              <div class="col-sm-7">
                <input
                  v-if="item?.translates === true"
                  type="text"
                  class="form-control"
                  :id="'input' + item.name"
                  v-model="valuesData[name][item.name][i - 1][this.language]"
                />
                <input
                  v-else
                  type="text"
                  class="form-control"
                  :id="'input' + item.name"
                  v-model="valuesData[name][item.name][i - 1]"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer custom-input">
          <div class="row w-100">
            <div class="col-3">
              <div class="input-group" v-if="translatable">
                <label>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path
                      d="M18.5 10l4.4 11h-2.155l-1.201-3h-4.09l-1.199 3h-2.154L16.5 10h2zM10 2v2h6v2h-1.968a18.222 18.222 0 0 1-3.62 6.301 14.864 14.864 0 0 0 2.336 1.707l-.751 1.878A17.015 17.015 0 0 1 9 13.725a16.676 16.676 0 0 1-6.201 3.548l-.536-1.929a14.7 14.7 0 0 0 5.327-3.042A18.078 18.078 0 0 1 4.767 8h2.24A16.032 16.032 0 0 0 9 10.877a16.165 16.165 0 0 0 2.91-4.876L2 6V4h6V2h2zm7.5 10.885L16.253 16h2.492L17.5 12.885z"
                    />
                  </svg>
                </label>
                <select class="form-control" v-model="language">
                  <option
                    v-for="lang in languages"
                    :key="lang.code"
                    :value="lang.code"
                  >
                    {{ lang.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col"></div>
            <div class="col-auto">
              <button class="btn btn-primary" v-on:click="save">Save</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <VideoUpload v-model:show="showVideoModal" />
</template>

<script>
import http from "../../modules/http";
import VideoUpload from "./VideoUpload";
import { useStore } from "vuex";

const bootstrap = require("bootstrap");

export default {
  name: "EditContent",
  components: { VideoUpload },
  props: {
    show: Boolean,
    items: Object,
    values: Object,
    languages: Array,
    name: String,
    i: Number,
  },
  emits: ["update:show", "update:values", "addBlock"],
  data() {
    return {
      store: useStore(),
      valuesData: {},
      myModal: undefined,
      quill: {},
      language: undefined,
      links: [],
      products: [],
      q: "",
      productSelector: undefined,
      categories: [],
      characteristics: [],
      images: {},
      refresh: false,
      translatable: false,
      uploadPercent: 0,
      uploadProccess: false,
      showVideoModal: false,
    };
  },
  computed: {
    mediaServer() {
      return process.env.VUE_APP_MEDIA_URL;
    },
    tags() {
      return this.store.state.user.tags.products;
    },
  },
  mounted() {
    this.myModal = new bootstrap.Modal(this.$refs.modal, {
      backdrop: "static",
    });
    if (this.show) {
      this.myModal.show();
    } else {
      this.myModal.hide();
    }
  },
  watch: {
    languages() {
      if (this.language === undefined && this.languages.length > 0) {
        this.language = this.languages[0].code;
      }
    },
    values: {
      deep: true,
      handler() {
        this.valuesData = Object.assign({}, this.values);
      },
    },
    show() {
      if (this.show) {
        this.myModal.show();
        this.translatable = false;
        setTimeout(() => {
          for (const item of this.items[this.name].values) {
            if (item?.translates === true) {
              this.translatable = true;
            }
            if (item.type === "link" || item.type === "product") {
              if (this.links.length < 1) {
                http
                  .fetch("/products/categories/products/" + this.language)
                  .then((data) => {
                    this.addCategories(data["data"]);
                  });
                http
                  .fetch(
                    "/products/products/characteristics?language=" +
                      this.language
                  )
                  .then((data) => {
                    this.characteristics = data;
                  });
                http.fetch("/page").then((data) => {
                  for (const item of data.data) {
                    item.target_page_id = item.id;
                    delete item.id;
                    this.links.push(item);
                  }
                });
              }
            } else if (item.type === "rich-text") {
              this.loadQuill(item);
            }
          }
        }, 200);
      } else {
        this.myModal.hide();
      }
    },
    language() {
      this.refresh = true;
      setTimeout(() => {
        this.refresh = false;
        setTimeout(() => {
          for (const item of this.items[this.name].values) {
            if (item.type === "rich-text") {
              this.loadQuill(item);
            }
          }
        }, 200);
      }, 200);
    },
  },
  methods: {
    loadQuill(item) {
      // eslint-disable-next-line no-undef
      this.quill[item.name] = new Quill(this.$refs["edit-" + item.name][0], {
        theme: "snow",
        formats: [
          "bold",
          "color",
          "code",
          "italic",
          "link",
          "strike",
          "script",
          "blockquote",
          "header",
          "indent",
          "list",
          "align",
          "video",
        ],
        modules: {
          toolbar: [
            [{ header: [1, 2, 3, 4, false] }, { header: 1 }, { header: 2 }], // custom button values
            ["bold", "italic", "strike"],
            ["link", "blockquote"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ align: "" }, { align: "center" }, { align: "right" }],
            ["video", "image"],
            ["clean"],
          ],
        },
      });
    },
    addCategories(categories) {
      for (const item of categories) {
        item.target_category_id = item.id;
        delete item.id;
        this.links.push(item);
        this.categories.push(item);
        if (item.categories !== undefined) {
          this.addCategories(item.categories);
        }
      }
    },
    getImageLinks(option) {
      if (option.remote) {
        return {
          src: option.path,
        };
      }

      let types = {
        0: { name: "PRODUCT", sizes: [262, 582, 720, 1440] },
        2: {
          name: "CATEGORY",
          sizes: [360, 480, 540, 768, 960, 1280, 1440, 1600],
        },
        3: {
          name: "COVER",
          sizes: [360, 480, 537, 540, 768, 960, 1280, 1440, 1600],
        },
        4: { name: "COLOR", sizes: [32, 64, 128] },
        5: { name: "MENU", sizes: [64, 128, 262, 360, 480] },
      };

      let sizes = null;
      if (types[option.type] !== undefined) {
        sizes = {};
        for (const size of types[option.type].sizes) {
          sizes[size] =
            this.mediaServer +
            "/" +
            option.path +
            "/" +
            size +
            "-" +
            option.name +
            ".webp";
          // option.format;
        }
      }

      return {
        id: option.id,
        src: this.mediaServer + "/" + option.path + "/" + option.name + ".webp",
        //option.format,
        sizes: sizes,
      };
    },
    getLink(c) {
      if (c.target_url !== undefined) {
        return c.target_url;
      }
      if (c.target_category_id !== undefined) {
        return "/category/" + c.target_category_id + "-" + c.slug;
      }
      if (c.target_page_id !== undefined) {
        return "/" + c.target_page_id + "-" + c.slug;
      }
    },
    fetchProducts(search, loading) {
      if (search.length < 3) {
        return;
      }
      this.q = search;
      loading(true);
      http
        .fetch("/products/products/search", {
          q: search,
        })
        .then((data) => {
          this.products = data;
          loading(false);
        })
        .catch(() => {
          loading(false);
        });
    },
    fetchImages(search, loading, name) {
      if (search.length < 3) {
        return;
      }
      loading(true);
      http
        .fetch("/images/search", {
          q: search,
        })
        .then((data) => {
          this.images[name] = data;
          loading(false);
        })
        .catch(() => {
          loading(false);
        });
    },
    browseImages(name, imageType = 3) {
      http.fetch("/images?type=" + imageType).then((data) => {
        this.images[name] = data;
      });
    },
    changeToFilter(name, e, i) {
      if (
        this.valuesData[name][e][i - 1]?.target_category_id === null ||
        this.valuesData[name][e][i - 1]?.target_category_id === undefined
      ) {
        this.valuesData[name][e][i - 1] = {
          target_category_id: 0,
          characteristics: {},
          tags: [],
        };
        for (const characteristics of this.characteristics) {
          this.valuesData[name][e][i - 1].characteristics[characteristics.id] =
            [];
        }
      }
      if (
          this.valuesData[name][e][i - 1]?.tags === null ||
          this.valuesData[name][e][i - 1]?.tags === undefined
      ) {
        this.valuesData[name][e][i - 1].tags = [];
      }
    },
    save() {
      for (const item of this.items[this.name].values) {
        if (item.type === "rich-text") {
          if (item?.translates === true) {
            this.valuesData[this.name][item.name][this.i - 1][this.language] =
              this.quill[item.name].root.innerHTML;
          } else {
            this.valuesData[this.name][item.name][this.i - 1] =
              this.quill[item.name].root.innerHTML;
          }
        }
      }
      this.$emit("update:values", this.valuesData);
      this.$emit("save");
      this.$emit("update:show", false);
    },
    startUpload(id) {
      document.getElementById("file_" + id).click();
      // this.$refs["file_" + i].click();
    },
    btoa(str) {
      return window.btoa(str);
    },
    upload(name, item, i) {
      //  let file = this.$refs["file" + window.btoa(name+item+i)].files[0];
      let file = document.getElementById("file_" + window.btoa(name + item + i))
        .files[0];
      let type = this.items[this.name].values.find(
        (e) => e.name === item
      ).image;

      const request = new XMLHttpRequest();
      this.uploadPercent = 0;
      this.uploadProccess = true;
      const id = Math.round(new Date() / 1000);
      const fileName = file.name.replace(/ /g, "-");
      let send = { type: type, id: id, images: [fileName] };

      const _this = this;
      http.fetch("/files/upload", send).then((data) => {
        const formData = new FormData();
        formData.append("image", file, fileName);
        formData.append("token", data.token);
        formData.append("album", id);
        formData.append("category", type);

        request.open("POST", process.env.VUE_APP_MEDIA_API + "/images/upload");

        request.upload.onprogress = function (evt) {
          let e = Math.ceil((evt.loaded / file.size) * 60);
          _this.uploadPercent = e;
          if (e > 59) {
            let interval = setInterval(() => {
              if (_this.uploadProccess && _this.uploadPercent < 98) {
                _this.uploadPercent += 1;
              } else {
                clearInterval(interval);
              }
            }, 2000);
          }
        };

        request.onload = function () {
          if (request.status >= 200 && request.status < 300) {
            let data2 = JSON.parse(request.responseText);
            _this.images[item] = data2.operation.images;
            _this.uploadPercent = 98;
            setTimeout(() => {
              _this.uploadPercent = 100;
              _this.uploadProccess = false;
              console.log(item);
              console.log(name);
              console.log(_this.valuesData[name][item]);
              console.log(i);
              _this.valuesData[name][item][i - 1] = _this.getImageLinks(
                data2.operation.images[0]
              );
            }, 200);
          } else {
            console.log("oh no");
            _this.uploadProccess = false;
          }
        };

        request.onerror = function () {
          _this.uploadProccess = false;
        };

        request.send(formData);
      });
    },
  },
};
</script>
